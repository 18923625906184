import React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact"
import Hours from "../components/hours"

const IndexPage = () => {
    return (
        <Layout>
            <div id="404">
              <p>
                What the cluck!
              </p>
              <p>
                This address does not have a page associated with it. Please 
                use the navigation above or below to go to an existing page. 
                Alternatively, stop in and order some fried chicken!
              </p>
            </div>
            <Contact />
            <Hours />
        </Layout>
    )
}

export default IndexPage
